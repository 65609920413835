/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      avatarPortrait
      subscriptionId
      paymentId
      avatar
      firstName
      lastName
      email
      bio
      dateOfBirth
      instagram
      tikTok
      twitter
      discord
      website
      toStake
      staked
      balance
      interests
      education
      achievements
      jobs
      blogsAndVideos
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        avatarPortrait
        subscriptionId
        paymentId
        avatar
        firstName
        lastName
        email
        bio
        dateOfBirth
        instagram
        tikTok
        twitter
        discord
        website
        toStake
        staked
        balance
        interests
        education
        achievements
        jobs
        blogsAndVideos
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubscriptionHundo = /* GraphQL */ `
  query GetSubscriptionHundo($id: ID!) {
    getSubscriptionHundo(id: $id) {
      id
      price
      chargeBeeId
      stripeId
      playerId
      email
      billingCyclesPerYear
      startTerm
      createdAt
      updatedAt
    }
  }
`;
export const listSubscriptionHundos = /* GraphQL */ `
  query ListSubscriptionHundos(
    $filter: ModelSubscriptionHundoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionHundos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        price
        chargeBeeId
        stripeId
        playerId
        email
        billingCyclesPerYear
        startTerm
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCourse = /* GraphQL */ `
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      id
      playerId
      scorePercentage
      progress
      passed
      courseId
      staked
      createdAt
      updatedAt
    }
  }
`;
export const listCourses = /* GraphQL */ `
  query ListCourses(
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerId
        scorePercentage
        progress
        passed
        courseId
        staked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      playerId
      courseId
      moduleId
      questionId
      question
      answers
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playerId
        courseId
        moduleId
        questionId
        question
        answers
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
