type ModalProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export interface ModalState {
  isOpen: boolean;
  modalType: string;
  props: ModalProps;
}

export type ModalReducerAction = { type: 'SET_OPEN'; modalType: string; props: ModalProps } | { type: 'SET_CLOSED' };

const initialState = {
  isOpen: false,
  modalType: '',
  props: {},
};

export function modalReducer(state: ModalState, action: ModalReducerAction): ModalState {
  switch (action.type) {
    case 'SET_OPEN': {
      return {
        isOpen: true,
        modalType: action.modalType,
        props: action.props,
      };
    }

    case 'SET_CLOSED': {
      return initialState;
    }

    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
    }
  }
}
