import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import { useState, useEffect, useReducer } from 'react';
import Loader from '../components/Loader';
import { hotjar } from 'react-hotjar';
import dynamic from 'next/dynamic';
import { appWithTranslation } from 'next-i18next';
import { Hub, Amplify } from 'aws-amplify';
const NavBar = dynamic(() => import('../containers/NavBar'), { ssr: false });

import { ModalProvider } from '../context/modal/context';
import awsExports from '../aws-exports';
import styles from './app.module.scss';
import '../shared/styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../context/auth/context';
import { reducer, initialState } from '../context/auth/reducer';
import { fetchUserData } from '../shared/utils/player';

Amplify.configure({ ...awsExports, ssr: true });

const canTrack = process.env.BUILD_ENV === 'prod';

const App = ({ Component, pageProps }: AppProps) => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const currentPage = router.pathname.toLowerCase();
  const authPages = ['login', 'register', 'subscription', 'success', '404'];
  const showNavBar = !authPages.some((page) => currentPage.includes(page));
  const [user, setUser] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchUserData(setUser);
  }, [setUser]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      const promoCodeURL = router?.query?.PROMO_CODE ? `?PROMO_CODE=${router?.query?.PROMO_CODE}` : '';
      if (event === 'autoSignIn') {
        router.push(`subscription${promoCodeURL}`);
      } else if (event === 'autoSignIn_failure') {
        router.push('login');
      }
    });
  }, []);

  useEffect(() => {
    const onStartLoad = () => {
      setLoading(true);
    };

    const onEndLoad = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', onStartLoad);
    router.events.on('routeChangeComplete', onEndLoad);
    router.events.on('routeChangeError', onEndLoad);

    return () => {
      router.events.off('routeChangeStart', onStartLoad);
      router.events.off('routeChangeComplete', onEndLoad);
      router.events.off('routeChangeError', onEndLoad);
    };
  }, [router.events]);

  useEffect(() => {
    if (canTrack) {
      hotjar.initialize(process.env.HOTJAR_SITE_ID, process.env.HOTJAR_SNIPPET_VERSION);
    }

    Modal.setAppElement('#app');
  }, []);

  return (
    <div id="app">
      <ModalProvider>
        <AuthContext.Provider value={{ user, setUser }}>
          {showNavBar && <NavBar />}
          <div className={showNavBar ? styles.page : styles['page-loading']}>
            {loading ? (
              <div className={styles.loading}>
                <div className={styles.loader}>
                  <Loader />
                </div>
              </div>
            ) : (
              <Component {...pageProps} />
            )}
          </div>
          <ToastContainer />
        </AuthContext.Provider>
      </ModalProvider>
    </div>
  );
};

export default appWithTranslation(App);

/*
  import { WagmiConfig, configureChains, createClient, chain } from 'wagmi';
  import { alchemyProvider } from 'wagmi/providers/alchemy';
  import { publicProvider } from 'wagmi/providers/public';
  import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
  import '@rainbow-me/rainbowkit/styles.css';

  const alchemyId = canTrack ? process.env.ALCHEMY_API_KEY_MAINNET : process.env.ALCHEMY_API_KEY_TESTNET;
  
  const { chains, provider } = configureChains(
    [chain.polygonMumbai, chain.polygon],
    [alchemyProvider({ alchemyId }), publicProvider()],
  );

  const { connectors } = getDefaultWallets({
    appName: 'Campus',
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        ...
      </RainbowKitProvider>
    </WagmiConfig>
  )
**/
