import { GetPlayerQuery } from './../../API';
import { API, Auth } from 'aws-amplify';
import { getPlayer } from '../../graphql/queries';
export const fetchUserData = async (setUser: Function, id?: string) => {
  try {
    let userId = id;
    if (!id) {
      const { username } = await Auth.currentAuthenticatedUser();
      userId = username;
    }

    const { data } = (await API.graphql({
      query: getPlayer,
      variables: {
        id: userId,
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
    })) as { data: GetPlayerQuery };

    setUser({
      type: 'edit',
      payload: data?.getPlayer,
    });
  } catch (error: unknown) {
    setUser({ type: 'reset' });
    console.error(error);
  }
};
