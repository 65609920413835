import { createContext } from 'react';
import { Player } from '../../types';

export const AuthContext = createContext<{
  user: Player;
  setUser: Function;
}>({
  user: {
    id: '',
    avatarPortrait: '',
    avatar: '',
    firstName: '',
    lastName: '',
    email: '',
    bio: '',
    dateOfBirth: null,
    instagram: '',
    tikTok: '',
    twitter: '',
    discord: '',
    website: '',
    toStake: null,
    staked: null,
    balance: null,
    interests: [''],
    education: [''],
    achievements: [''],
    jobs: [''],
    blogsAndVideos: [''],
    courses: [''],
    paymentId: '',
    subscriptionId: '',
  },
  setUser: () => null,
});
