// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const buildEnv = process.env.BUILD_ENV === 'prod';

Sentry.init({
  dsn: SENTRY_DSN,
  debug: !buildEnv,
  environment: process.env.BUILD_ENV,
  attachStacktrace: !buildEnv,
  autoSessionTracking: buildEnv,
  tracesSampleRate: 1.0,
});
