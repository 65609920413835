import { createContext, useContext, useMemo, useReducer } from 'react';

import { modalReducer, ModalReducerAction, ModalState } from './reducer';

type ModalContextValue = [ModalState, React.Dispatch<ModalReducerAction>];

export enum ModalTypes {
  NftModal = 'NftModal',
}

const ModalContext = createContext<ModalContextValue | null>(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ModalProvider = (props: any) => {
  const [state, dispatch] = useReducer(modalReducer, {
    isOpen: false,
    modalType: '',
    props: {},
  });

  const value = useMemo(() => [state, dispatch], [state]) as ModalContextValue;

  return <ModalContext.Provider value={value} {...props} />;
};

function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  const [state, dispatch] = context;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setOpen = (modalType: ModalTypes, props?: any) => dispatch({ type: 'SET_OPEN', modalType, props });

  const setClosed = () => dispatch({ type: 'SET_CLOSED' });

  return {
    state,
    dispatch,
    setOpen,
    setClosed,
  };
}

export { ModalProvider, useModal };
