import { Player } from '../../types';

export const initialState: Player = {
  id: '',
  subscriptionId: '',
  paymentId: '',
  avatarPortrait: '',
  avatar: '',
  firstName: '',
  lastName: '',
  email: '',
  bio: '',
  dateOfBirth: null,
  instagram: '',
  tikTok: '',
  twitter: '',
  discord: '',
  website: '',
  toStake: null,
  staked: null,
  balance: null,
  interests: [''],
  education: [''],
  achievements: [''],
  jobs: [''],
  blogsAndVideos: [''],
  courses: [''],
};

export const reducer = (
  state: Player,
  action: {
    type: string;
    payload: Player;
  },
) => {
  switch (action.type) {
    case 'edit':
      return {
        ...state,
        ...action.payload,
      };
    case 'reset':
      return initialState;

    default:
      return state;
  }
};
